import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import AppWithAuth from './AppWithAuth';
import reportWebVitals from './reportWebVitals';
import { Amplify } from "aws-amplify";
import awsConfig from "./awsConfig";
import { createTheme } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { brandBlue, lightBlue } from "assets/colors";

Amplify.configure(awsConfig);

const theme = createTheme({
  typography: {
    fontFamily: 'Helvetica'
  },
  palette: {
    primary: { main: brandBlue },
    secondary: { main: lightBlue }
  }
});

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppWithAuth />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
